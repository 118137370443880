import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import Image from "./core/Image"

export default function PrizesBlock({ title, subtitle, text, images, isVertical, ctaLink, ctaText, className, color }) {
  return (
    <section 
      className={`
        container prizes-block${isVertical ? '--vertical' : ''} 
        ${className} 
      `}
    >
      { isVertical 
        && <h2 
          className={`prizes-block__title--top text--${color}`}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >{ title }</h2> 
      }
      
      <div 
        className="prizes-block__prizes sal-disabled"
      >
        {images.map((image, i) => (
          <div 
            key={i}
            className="prizes-block__prizes-image"
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-delay={i * 100}
            data-sal-easing="ease"
          >
            <Image 
              className={`prizes-block__prizes-img prizes-block__prizes-img--${image.originalName.replace(/\.[^/.]+$/, "")}`}
              data={image} 
              alt="" 
              objectFit="contain"  
            />
          </div>
        ))}
      </div>

      <div className={`prizes-block__info text--${color}`}>
        { !isVertical 
          && <h2 
            className="prizes-block__title--bottom"
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >{ title }</h2> 
        }
        <div 
          className="prizes-block__copy"
          data-sal="fade"
          data-sal-delay={isVertical ? 0 : 500}
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <h3 className="prizes-block__subtitle">{ subtitle }</h3>
          <p className="prizes-block__text">{ text }</p>
          <Button 
            colour="primary" 
            text={ctaText} 
            type="link" 
            link={ctaLink}
            gatsbyLink={true}  
          />
        </div>
      </div>
    </section>
  )
}

PrizesBlock.defaultProps = {
  isVertical: false,
  color: 'blue',
}

PrizesBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  isVertical: PropTypes.bool,
}
