import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import PrizesBlock from "../components/PrizesBlock"
import FormBlock from "../components/FormBlock"

const Prizespage = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/prizes/" }) {
        body
        frontmatter {
          path
          title
          hero {
            image
          }
          sliderBlock1 {
            title
            subtitle
            text
            ctaLink
            ctaText
            images
            background
            color
          }
          sliderBlock2 {
            title
            subtitle
            text
            ctaLink
            ctaText
            images
            background
            color
          }
          formBlock {
            title
            className
          }
        }
      }
      images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "assets/images"}}) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)
  
  const {
    title,
    hero,
    sliderBlock1,
    sliderBlock2,
    formBlock
  } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name => images.find(({originalName}) => originalName === name)

  return (
    <>
      <SEO title={title} />

      <Hero image={getImage(hero.image)} />
      
      <PrizesBlock 
        {...sliderBlock1}
        isVertical={false}
        className="prizes-page__block1"
        images={sliderBlock1.images.map(getImage)}
      />
      
      <PrizesBlock 
        {...sliderBlock2}
        isVertical={true}
        className="prizes-page__block2 background-gradient--blue"
        images={sliderBlock2.images.map(getImage)}
      />

      <FormBlock {...formBlock} />
    </>
  )
}

export default Prizespage